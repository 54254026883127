import {
  useEffect, useState, useCallback, ReactNode,
} from 'react';
import {
  Fab,
  TextField,
  CircularProgress,
  Stack,
  Button,
} from '@mui/material';

interface Props {
  redeemCode: string | string[] | undefined;
  isReady: boolean;
  loading: boolean;
  showGoBackButton: boolean;
  handleApplyAccessCode: (code: string) => void;
  handleBackClick: () => void;
  renderErrorState: () => ReactNode;
  showError: boolean;
  handleTextChange: () => void;
}

const CardContent = (props: Props) => {
  const {
    redeemCode,
    isReady,
    loading,
    handleApplyAccessCode,
    renderErrorState,
    handleBackClick,
    showGoBackButton,
    showError,
    handleTextChange,
  } = props;
  const [textCode, setTextCode] = useState(redeemCode || '');
  useEffect(() => {
    if (isReady && redeemCode) {
      setTextCode(redeemCode);
    }
  }, [redeemCode, isReady]);

  useEffect(() => {
    if (typeof redeemCode === 'string') {
      handleApplyAccessCode(redeemCode);
    }
  }, [handleApplyAccessCode, redeemCode]);

  const handleChangeText = useCallback((event) => {
    setTextCode(event.target.value);
    handleTextChange();
  }, [handleTextChange]);

  const handleSubmit = () => {
    if (textCode && textCode.length >= 6) {
      handleApplyAccessCode(textCode as string);
    }
  };

  return (
    <Stack direction="column" gap={2} alignItems="center">
      <>
        <TextField
          variant="outlined"
          placeholder="XXX-XXX-XXXXXX"
          autoFocus
          fullWidth
          defaultValue={redeemCode}
          onChange={handleChangeText}
          disabled={loading}
          value={textCode}
        />
        {showError ? renderErrorState() : null}
      </>
      <Fab
        variant="extended"
        color="primary"
        style={{ width: '100%' }}
        onClick={handleSubmit}
        disabled={showError || textCode.length < 6 || loading}
      >
        {loading ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          'Submit'
        )}
      </Fab>
      {showGoBackButton ? (
        <Button
          disabled={loading}
          onClick={handleBackClick}
          color="secondary"
        >
          Go Back
        </Button>
      ) : null}
    </Stack>
  );
};

export default CardContent;
