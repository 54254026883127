/* -----------------Globals--------------- */
import React, {
  useReducer,
} from 'react';
import Router, {
  useRouter,
} from 'next/router';

/* -----------------Types--------------- */
import {
  PasswordRecoveryActionType,
} from './types';
import reducer from './reducer';

/* -----------------Functions--------------- */
import { forgotPasswordReset, forgotPasswordVerification } from '../../../functions/auth';
import { getErrorMessage } from '../../../utils/auth';

/* -----------------Constants--------------- */
import {
  getRecoveryCodeHelperText,
  success,
} from '../../../constants/auth';
import {
  initialState,
} from './constants';
/* -----------------Child components--------------- */
import Snackbar from '../../common/Snackbar';
import PasswordRecovery from '../PasswordRecovery';

/* -----------------Component--------------- */
const PasswordRecoveryContainer = () => {
  const {
    query: {
      username,
    },
  } = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);

  const helperText = getRecoveryCodeHelperText(username as string);

  const {
    recoveryCode,
    newPassword,
    snackbarState,
    errorMessage,
    isSubmitting,
    reEnteredNewPassword,
  } = state;
  const handleResendOtp = async () => {
    if (username) {
      dispatch({
        type: PasswordRecoveryActionType.setResendingOtp,
        payload: true,
      });
      forgotPasswordVerification(username as string).finally(() => {
        dispatch({
          type: PasswordRecoveryActionType.setResendingOtp,
          payload: false,
        });
        dispatch({
          type: PasswordRecoveryActionType.setSnackbarState,
          payload: {
            message: success.codeSent,
          },
        });
      });
    }
  };

  const handleChangePassword = async () => {
    dispatch({
      type: PasswordRecoveryActionType.setIsSubmitting,
      payload: true,
    });
    try {
      if (username && recoveryCode && newPassword) {
        await forgotPasswordReset(
            username as string,
            recoveryCode as string,
            newPassword,
        );
        dispatch({
          type: PasswordRecoveryActionType.setSnackbarState,
          payload: {
            message: success.reset,
            href: 'login',
          },
        });
      }
    } catch (err) {
      const typedError = err as string;
      const exception = decodeURIComponent(typedError).split(':')[0];
      const errMsg = getErrorMessage(exception);
      dispatch({
        type: PasswordRecoveryActionType.setErrorMessage,
        payload: errMsg,
      });
    } finally {
      dispatch({
        type: PasswordRecoveryActionType.setIsSubmitting,
        payload: false,
      });
    }
  };

  const handleCloseSnackbar = () => {
    if (snackbarState?.href) {
      Router.push(`${Router.pathname}/?view=${snackbarState?.href}`, undefined, {
        shallow: true,
      });
    }
    dispatch({
      type: PasswordRecoveryActionType.setSnackbarState,
      payload: undefined,
    });
  };

  const isSomeFieldEmpty = [recoveryCode, newPassword, reEnteredNewPassword].some((item) => !item);
  const isSomePasswordFieldEmpty = !newPassword || !reEnteredNewPassword;
  const showPasswordDontMatchError = isSomePasswordFieldEmpty
    ? false : newPassword !== reEnteredNewPassword;

  const showError = typeof errorMessage === 'string';

  const disableButton = isSubmitting || showPasswordDontMatchError || isSomeFieldEmpty;
  const showSnackbar = typeof snackbarState?.message === 'string';
  return (
    <>
      <PasswordRecovery
        handleResendOtp={handleResendOtp}
        handleChangePassword={handleChangePassword}
        disableButton={disableButton}
        showError={showError}
        showPasswordDontMatchError={showPasswordDontMatchError}
        handleDispatch={dispatch}
        state={state}
        recoveryHelperText={helperText}
      />
      {showSnackbar ? (
        <Snackbar
          open={showSnackbar}
          handleClose={handleCloseSnackbar}
          message={snackbarState.message}
        />
      ) : null}

    </>
  );
};
export default PasswordRecoveryContainer;
