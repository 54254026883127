/* -----------------Globals--------------- */
import React from 'react';
import { useTenantContext } from '../../context/TenantContext';

/* -----------------Child components--------------- */
import AuthInput from './Fields';
import AuthFooter from './Footer';
import PasswordLessField from './passwordLess/Fields';
import Footer from './passwordLess/Footer';

/* -----------------Props--------------- */
type Props = {
  refreshAuth: () => void,
  handleChangeView: (v : string) => void,

};

/* -----------------Component--------------- */
const SignUp = (props: Props) => {
  const {
    refreshAuth,
    handleChangeView,
  } = props;
  const {
    passwordLessFlow,
  } = useTenantContext();
  return (
    passwordLessFlow
      ? (
        <>
          <PasswordLessField />
          <Footer />
        </>
      )
      : (
        <>
          <AuthInput
            variant="signup"
            refreshAuth={refreshAuth}
          />
          <AuthFooter
            variant="signup"
            secondaryAction={() => handleChangeView('login')}
          />
        </>
      )
  );
};

export default SignUp;
