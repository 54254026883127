/* -----------------Child components--------------- */
import AuthInput from './Fields';
import AuthFooter from './Footer';

/* -----------------Props--------------- */
type Props = {
  handleSignUp: () => void,
  handleForgot?: () => void,
  refreshAuth: () => void
};

/* -----------------Component--------------- */
const SignIn = (props: Props) => {
  const {
    refreshAuth,
    handleSignUp,
    handleForgot,
  } = props;

  return (
    <>

      <AuthInput
        variant="login"
        refreshAuth={refreshAuth}
      />
      <AuthFooter
        variant="login"
        primaryAction={handleForgot}
        secondaryAction={handleSignUp}
      />
    </>
  );
};

export default SignIn;
