/* -----------------Child components--------------- */
import Card from './Card';
import AuthViews from '../auth/AuthViews';
import { ViewType } from '../../utils/constants';

type Props = {
  title: string;
  subtitle: string;
  image?: string;
  view: ViewType;
};

const Auth = (props: Props) => {
  const {
    title,
    subtitle,
    image,
    view,
  } = props;

  return (
    <>
      <Card
        title={title}
        subtitle={subtitle}
        image={image}
      >
        <AuthViews view={view} />
      </Card>
    </>
  );
};

export default Auth;
