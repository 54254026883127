/* -----------------Globals--------------- */
import { useState } from 'react';
import Router, {
  useRouter,
} from 'next/router';
import Cookies from 'js-cookie';
import { useAtom } from 'jotai';
import OtpInput from 'react-otp-input';
/* -----------------Third parties--------------- */
import API from '@aws-amplify/api';

/* -----------------UI--------------- */
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';

/* -----------------Types--------------- */
import { Tenant } from '../../types/tenant';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '../../configs/amplify';

/* -----------------Constants--------------- */
import { otp, success } from '../../constants/auth';
import { encodeStringToSlug } from '../../utils/constants';

/* -----------------Context--------------- */
import { useAuthContext } from '../../context/AuthContext';
import {
  useTenantContext,
} from '../../context/TenantContext';

/* -----------------Auth Functions--------------- */
import {
  isAuthenticated,
  resendOTP,
  handleCustomChallenge,
  signInUsingEmail,
} from '../../functions/auth';

/* -----------------Child components--------------- */
import Snackbar from '../common/Snackbar';
import CustomHelperText from '../common/CustomHelperText';

const OneTimePassword = () => {
  const {
    length: otpLength, invalidOtp,
  } = otp;
  const {
    passwordLessFlow,
    tenant,
    appId = '',
    tenantIdAuth,
  } = useTenantContext();

  const { cognitoUserObjectAtom, refreshAuth } = useAuthContext();
  const [cognitouserobject, setCognitoUserObject] = useAtom(cognitoUserObjectAtom);
  const {
    pathname,
    query: {
      email,
      redeemCode,
    },
  } = useRouter();

  const theme = useTheme();
  const finalEmail = encodeStringToSlug(email as string);
  const username = Cookies.get('username');
  const [code, setCode] = useState<string | undefined>(undefined);
  const [resendingOtp, setResendingOtp] = useState(false);
  const [submittingCode, setSubmittingCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const resetOTPStates = () => {
    setResendingOtp(false);
    setShowSnackbar(true);
  };
  const handleResendOtp = async () => {
    setResendingOtp(true);
    if (passwordLessFlow) {
      const newCognitoUserObject = await signInUsingEmail(finalEmail, appId, tenantIdAuth);
      setCognitoUserObject(newCognitoUserObject);
      resetOTPStates();
    } else if (username) {
      resendOTP(username).finally(() => {
        resetOTPStates();
      });
    }
  };

  const handlePostSubmitCode = async () => {
    API.configure(setUpAmplifyConfig(tenant as Tenant, true));
    await isAuthenticated();
    if (refreshAuth) { await refreshAuth(); }
    const finalPath = `${pathname}?view=redeem${redeemCode ? `&redeemCode=${redeemCode}` : ''}`;
    Router.push(finalPath, undefined, {
      shallow: true,
    });
  };

  const handleSubmitCode = async () => {
    try {
      if (code) {
        setSubmittingCode(true);
        if (passwordLessFlow && cognitouserobject) {
          await handleCustomChallenge(code, cognitouserobject);
          await handlePostSubmitCode();
        }
      }
    } catch (error) {
      setShowError(true);
    } finally {
      setSubmittingCode(false);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };
  const Footer = () => {
    const body = 'Didn\'t receive a valid code?';
    return (
      <Stack
        direction="row"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2">
          {body}
        </Typography>
        <LoadingButton
          variant="text"
          color="secondary"
          disabled={resendingOtp}
          loading={resendingOtp}
          sx={{
            textTransform: 'none',
            height: 32,
          }}
          onClick={handleResendOtp}
        >
          Resend
        </LoadingButton>
      </Stack>
    );
  };
  return (
    <>

      <OtpInput
        value={code}
        inputStyle={{
          width: '40px',
          height: '40px',
          margin: '0 4px',
          backgroundColor: theme.palette.background.paper,
          fontSize: '16px',
          borderRadius: '4px',
          borderColor: theme.palette.divider,
        }}
        containerStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 16,
        }}
        onChange={(e) => {
          if (showError) {
            setShowError(false);
          }
          setCode(e);
        }}
        numInputs={6}
        shouldAutoFocus
        renderInput={(props) => (
          <input
            {...props}
          />
        )}
      />
      { showError ? (
        <CustomHelperText
          error={showError}
          text={invalidOtp}
        />
      ) : null}
      <LoadingButton
        color="primary"
        variant="contained"
        style={{ width: '100%' }}
        onClick={handleSubmitCode}
        loading={submittingCode}
        disabled={!code || submittingCode || code.length < otpLength}
      >
        Continue
      </LoadingButton>

      <Footer />
      {showSnackbar ? (
        <Snackbar
          open={showSnackbar}
          handleClose={handleCloseSnackbar}
          message={success.codeSent}
        />
      ) : null}
    </>
  );
};

export default OneTimePassword;
