// @flow
/* -----------------Globals--------------- */
import React from 'react';
import Image from 'next/image';

/* -----------------UI--------------- */
import {
  Box,
  Card,
  Typography,
  Stack,
  Divider,
  CardContent,
  CircularProgress,
  Grid,
} from '@mui/material';

/* -----------------Child components--------------- */
import RenderMarkdown from '../common/RenderMarkdown';

type Props = {
  title: string,
  subtitle: string,
  image?: string,
  children : React.ReactNode,
  showCircularProgress?: boolean,
}

const Wrapper = (props: Props) => {
  const {
    children,
    title,
    subtitle,
    image,
    showCircularProgress,
  } = props;

  return (
    <Card
      sx={{
        borderRadius: '25px',
      }}
    >
      {showCircularProgress ? (
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '40vh' }}
          display="flex"
        >
          <CircularProgress size={48} />
        </Grid>
      ) : (
        <CardContent>
          <Stack spacing={2} direction="column" sx={{ textAlign: 'center' }}>
            {image ? (
              <Box width="100%" height={88} position="relative">
                <Image
                  src={image}
                  alt="Icon"
                  layout="fill"
                  objectFit="contain"
                />
              </Box>
            ) : null}
            <Typography variant="h5" display="block" fontWeight={600}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              display="block"
            >
              <RenderMarkdown
                source={subtitle}
              />
            </Typography>
            <Divider />
            {children}
          </Stack>
        </CardContent>
      )}

    </Card>
  );
};

export default Wrapper;
