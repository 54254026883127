import type {
  AccessCodeData,
} from '../types/redeem';

const getRedeemCodeSuccessText = (
  accessCodeData: AccessCodeData,
  userEmail: string,
  appName : string,
) => {
  const {
    bundleName,
    planLength,
  } = accessCodeData;

  return `You just purchased **${planLength}** access to the **${bundleName}** Study-Pack, powered by Memorang!
  
  Download the **${appName}** app and login with your email **${userEmail}**`;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getRedeemCodeSuccessText,
};
