/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {
  Fab,
  CircularProgress,
  Button,
} from '@mui/material';
import RedeemCard from './RedeemCard';
import RedeemCheckBox from './RedeemCheckbox';
import { ActiveCodesResult } from '../../relay/queries/mutations/activeCodes';

interface Props {
  title: string;
  description: string;
  activeCodes: ActiveCodesResult[];
  handleSelection: (bundleIds: string[]) => void;
  selectedIds: string[];
  loading: boolean;
  showError: boolean;
  renderErrorState: () => ReactNode;
  handleMultipleSelection: () => void;
  handleClickManual: () => void;
}

const AutoRedeemCard = (props: Props) => {
  const {
    title,
    description,
    activeCodes,
    handleSelection,
    selectedIds,
    loading,
    showError,
    renderErrorState,
    handleMultipleSelection,
    handleClickManual,
  } = props;
  return (
    <RedeemCard
      title={title}
      subtitle={description}
      icon={<ShoppingCartCheckoutIcon />}
    >
      <RedeemCheckBox
        activeCodes={activeCodes}
        handleSelection={handleSelection}
        disabled={loading}
        selectedIds={selectedIds}
      />
      {showError && renderErrorState()}
      <Fab
        variant="extended"
        color="primary"
        sx={{ width: '100%' }}
        onClick={handleMultipleSelection}
        disabled={showError || loading || selectedIds.length === 0}
      >
        {loading ? <CircularProgress color="secondary" size={24} /> : 'Redeem'}
      </Fab>
      <Button
        disabled={loading}
        onClick={handleClickManual}
        color="secondary"
        sx={{ marginTop: '1rem' }}
      >
        ENTER REDEMPTION CODE MANUALLY
      </Button>
    </RedeemCard>
  );
};

export default AutoRedeemCard;
