/* -----------------UI--------------- */
import {
  Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import React from 'react';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

type Props = {
  open: boolean;
  handleClose: () => void;
  message: string;
  duration?: number;
  severity?: AlertColor;
};
const CustomSnackbar = (props : Props) => {
  const {
    open,
    handleClose,
    message,
    duration = 2000,
    severity = 'success',
  } = props;
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
