import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { ActiveCodesResult } from '../../relay/queries/mutations/activeCodes';
import { useTheme } from '@mui/system';

interface CheckboxListProps {
  activeCodes: ActiveCodesResult[];
  handleSelection: (bundleId: string[]) => void
  disabled: boolean;
  selectedIds: string[];
}

export default function CheckboxListSecondary({
  activeCodes,
  handleSelection,
  disabled,
  selectedIds,
}: CheckboxListProps) {
  const theme = useTheme();

  const handleToggle = (value: string) => () => {
    if (selectedIds.includes(value)) {
      const index = selectedIds.indexOf(value);
      selectedIds.splice(index, 1);
      handleSelection([...selectedIds]);
    } else {
      const newSelectedIds = [...selectedIds, value];
      handleSelection(newSelectedIds);
    }
  };

  return (
    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {activeCodes.map(({ bundle: { id, name, icon: { url } } }) => {
        const labelId = `checkbox-list-label-${id}`;

        return (
          <ListItem key={id} disablePadding>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${id}`}
                  src={url}
                  variant="square"
                  sx={{
                    borderRadius: '22%',
                    border: `1pt solid ${theme.palette.divider}`,
                  }}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={name} secondary="Study Pack" />
            </ListItemButton>
            <Checkbox
              edge="end"
              onChange={handleToggle(id)}
              checked={selectedIds.includes(id)}
              inputProps={{ 'aria-labelledby': labelId }}
              disabled={disabled}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
