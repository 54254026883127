/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import ManualRedeemCard from './ManualRedeemCard';
import { useTenantContext } from '../../context/TenantContext';
import applyAccessCode from '../../relay/queries/mutations/applyAccessCode';
import fetchActiveCodes, { ActiveCodesResult } from '../../relay/queries/mutations/activeCodes';
import environment from '../../relay/env';
import AutoRedeemCard from './AutoRedeemCard';
import { AccessCodeData } from '../../types/redeem';
import { getTenantIcon } from '../../utils/image';
import RenderMarkdown from '../common/RenderMarkdown';

import { getPurchaseDetails } from '../../utils/constants';
import { useAuthContext } from '../../context/AuthContext';

interface Props {
  handleFabClick: (result: AccessCodeData) => void;
  title: string;
  subtitle: string;
}
type PurchaseDetails = {
  redeemPurchaseTitle: string;
  redeemPurchaseDescription: string;
};
const RedeemCode = (props: Props) => {
  const { handleFabClick, title, subtitle } = props;
  const [isManualFlow, setIsManualFlow] = useState(false);
  const { tenant } = useTenantContext();
  const {
    user,
  } = useAuthContext();
  const theme = useTheme();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [renderGoBackButton, setRenderGoBackButton] = useState(false);
  const [activeCodes, setActiveCodes] = useState<ActiveCodesResult[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { query, isReady } = useRouter();
  const { redeemCode } = query;

  const tenantIcon = getTenantIcon(tenant);

  const openZendesk = () => {
    window.zE('webWidget', 'open');
  };
  const handleClickManual = () => setIsManualFlow(true);
  const handleBackClick = () => {
    setIsManualFlow(false);
    setShowError(false);
  };
  const handleChangeText = () => setShowError(false);
  const renderErrorState = () => showError && (
    <Box
      color={theme.palette.error.main}
      textAlign="center"
      mb={2}
      style={{ cursor: 'pointer' }}
      onClick={openZendesk}
    >
      <Typography color="error">
        <RenderMarkdown customBoldColor="secondary" source={errorMessage} />
      </Typography>
    </Box>
  );

  const handleApplyAccessCode = useCallback(
    async (code?: string | string[] | undefined) => {
      if (typeof code === 'string') {
        setLoading(true);
        if (!environment.isRehydrated()) await environment.hydrate();
        if (code.length === 6 && code.match(/^[0-9]+$/)) {
          setShowError(true);
          setErrorMessage(
            'The code you have entered is a One Time Verification Code. Please enter a valid Access Code.',
          );
          setLoading(false);
        } else {
          applyAccessCode(code, environment)
            .then((res) => handleFabClick(res))
            .catch((e) => {
              setShowError(true);
              setErrorMessage(e);
            })
            .finally(() => setLoading(false));
        }
      }
    },
    [handleFabClick],
  );

  useEffect(() => {
    const ids = activeCodes.map(({ bundle: { id } }) => id);
    setSelectedIds(ids);
  }, [activeCodes]);

  const currentEmail = user ? user.attributes.email : null;

  const getActiveCodes = useCallback(async (email: string, tenantId: string) => {
    if (!environment.isRehydrated()) {
      await environment.hydrate();
    }
    const activeCodeList = await fetchActiveCodes(tenantId?.toUpperCase(), email);
    setTotalPurchase(activeCodeList?.length || 0);
    setActiveCodes(activeCodeList);
    setShowCircularProgress(false);
    if (activeCodeList.length > 0) {
      setRenderGoBackButton(true);
    }
  }, []);

  useEffect(() => {
    if (currentEmail && tenant) {
      getActiveCodes(currentEmail, tenant);
    }
  }, [currentEmail, tenant, getActiveCodes]);

  const handleSelection = (bundleIds: string[]) => {
    setSelectedIds([...bundleIds]);
    if (showError) {
      setShowError(false);
    }
  };

  const handleMultipleSelection = async () => {
    try {
      const selectedBundles = activeCodes.filter(({ bundle: { id } }) => selectedIds.includes(id));
      for (let i = 0; i < selectedBundles.length; i += 1) {
        const [firstCode] = selectedBundles[i].codes;
        // eslint-disable-next-line no-await-in-loop
        await handleApplyAccessCode(firstCode);
      }
    } catch (e) {
      setShowError(true);
      setErrorMessage(e as string);
    }
  };
  const {
    redeemPurchaseTitle,
    redeemPurchaseDescription,
  }: PurchaseDetails = getPurchaseDetails(currentEmail as string, totalPurchase);

  return (
    <>
      {(!activeCodes.length || isManualFlow) ? (
        <ManualRedeemCard
          showError={showError}
          title={title}
          subtitle={subtitle}
          tenantIcon={tenantIcon}
          showCircularProgress={showCircularProgress}
          redeemCode={redeemCode}
          isReady={isReady}
          handleApplyAccessCode={handleApplyAccessCode}
          renderErrorState={renderErrorState}
          handleBackClick={handleBackClick}
          renderGoBackButton={renderGoBackButton}
          loading={loading}
          handleChangeText={handleChangeText}
        />
      ) : (
        <AutoRedeemCard
          title={redeemPurchaseTitle}
          description={redeemPurchaseDescription}
          activeCodes={activeCodes}
          handleSelection={handleSelection}
          selectedIds={selectedIds}
          loading={loading}
          showError={showError}
          renderErrorState={renderErrorState}
          handleMultipleSelection={handleMultipleSelection}
          handleClickManual={handleClickManual}
        />
      )}

      {}
    </>
  );
};

export default RedeemCode;
