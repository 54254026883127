// The index of each array corresponds to the step number

const views = {
  signup: 'signup',
  login: 'login',
  reset: 'reset',
  forgot: 'forgot',
  otp: 'otp',
  redeem: 'redeem',
  success: 'success',
  passwordRecover: 'passwordRecover',
};

const steps = [
  [views.signup, views.login, views.reset, views.forgot, views.otp, views.passwordRecover], // auth
  [views.redeem], // redeem code
  [views.success], // download app
];

const subtitle = 'Enter your 11+ character purchase redemption code';

// 'Before you can redeem your purchase we need to
// otp your account first. Please open your email and enter
// the verification code we just sent you, then paste it below.'

const getInstructions = (
  partnerDisplayName: string,
  paramsEmail: string,
  passwordLessFlow?: boolean,
) => ({
  signup: {
    title: passwordLessFlow ? 'Continue with Email' : 'Redeem Digital Purchase',
    subtitle: passwordLessFlow
      ? ''
      : `A **${partnerDisplayName} test prep** account is required to continue`,
  },
  login: {
    title: 'Login',
    subtitle: 'Login to your existing **mobile test prep** account',
  },
  reset: {
    title: 'Reset Password',
    subtitle: 'Please enter your new password',
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle: 'Enter your username or email below',
  },
  otp: {
    title: 'Verify Email',
    subtitle: `We sent a 6-digit code to ${paramsEmail}. This code will expire shortly, so please enter it soon.`,
  },
  redeem: {
    title: 'Redeem Purchase',
    subtitle,
  },
  success: {
    title: '',
    subtitle: '',
  },
  passwordRecover: {
    title: 'Change Password',
    subtitle: '',
  },
});

type ViewType = keyof typeof views

const encodeStringToSlug = (value: string) => value?.toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .trim()
  .replace(/\s+/g, '+')
  .replace(/&/g, '&');

const getPurchaseDetails = (email: string, numPurchases: number) => ({
  redeemPurchaseTitle: 'Redeem Purchases',
  redeemPurchaseDescription: `There are **${numPurchases} purchases** associated with the email address **${email}** that have not been redeemed. Would you like to add them to your account?`,
});

export {
  views,
  steps,
  getInstructions,
  encodeStringToSlug,
  getPurchaseDetails,
};
export type {
  ViewType,
};
