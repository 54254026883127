/* --------------------- Globals -------------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import {
  Button,
  Typography,
  Stack,
} from '@mui/material';

/* -----------------Functions--------------- */
import { getFooterText } from '../../utils/auth';

/* -----------------Props--------------- */
type Props = {
  variant: string,
  primaryAction?: () => void,
  secondaryAction?: () => void,
  showContactSupport?: boolean
}

const AuthFooter = (props: Props) => {
  const {
    variant,
    secondaryAction,
    primaryAction,
    showContactSupport = true,
  } = props;
  const property = getFooterText(variant);

  const {
    primaryText,
    primaryActionName,
    secondaryText,
    secondaryActionName,
  } = property;

  const linkView = (name : string, actionName :string, action? : () => void) => (
    <Stack
      direction="row"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body2">
        {name}
      </Typography>
      <Button
        variant="text"
        color="secondary"
        sx={{
          textTransform: 'none',
          borderRadius: 4,
          height: 32,
        }}
        onClick={action}
      >
        {I18n.get(actionName)}
      </Button>
    </Stack>
  );
  // TODO: This is a bizarre abstraction
  return (
    <>
      {
        variant !== 'signup' && showContactSupport ? (
          linkView(primaryText, primaryActionName, primaryAction)
        ) : null
      }
      {
        linkView(secondaryText, secondaryActionName, secondaryAction)
      }
    </>
  );
};

export default AuthFooter;
