import { ReactNode } from 'react';
import Card from './Card';
import CardContent from './CardContent';

interface Props {
    title: string;
    subtitle: string;
    tenantIcon: string;
    showCircularProgress: boolean;
    redeemCode: string | string[] | undefined;
    isReady: boolean;
    handleApplyAccessCode: (accessCode: string) => void;
    loading: boolean;
    renderErrorState: () => ReactNode;
    handleBackClick: () => void;
    handleChangeText: () => void;
    renderGoBackButton: boolean;
    showError: boolean;
}

const ManualRedeemCard = (props: Props) => {
  const {
    title,
    subtitle,
    tenantIcon,
    showCircularProgress,
    redeemCode,
    isReady,
    handleApplyAccessCode,
    renderErrorState,
    handleBackClick,
    handleChangeText,
    renderGoBackButton,
    loading,
    showError,
  } = props;
  return (
    <Card
      title={title}
      subtitle={subtitle}
      image={tenantIcon}
      showCircularProgress={showCircularProgress}
    >
      <CardContent
        showError={showError}
        redeemCode={redeemCode}
        isReady={isReady}
        loading={loading}
        handleApplyAccessCode={handleApplyAccessCode}
        renderErrorState={renderErrorState}
        handleBackClick={handleBackClick}
        showGoBackButton={renderGoBackButton}
        handleTextChange={handleChangeText}
      />
    </Card>
  );
};

export default ManualRedeemCard;
