// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Card,
  Typography,
  Stack,
  Box,
  CardContent,
} from '@mui/material';

/* -----------------Child components--------------- */
import RenderMarkdown from '../common/RenderMarkdown';

type Props = {
  title: string,
  subtitle: string,
  icon: React.ReactElement,
  children : React.ReactNode
}

const RedeemCard = (props: Props) => {
  const {
    children,
    title,
    subtitle,
    icon,
  } = props;

  return (
    <Card sx={{
      borderRadius: '25px',
    }}
    >
      <CardContent>
        <Stack spacing={2} direction="column" sx={{ textAlign: 'center' }}>
          <Box sx={{
            width: '100%',
            marginTop: '15px',
          }}
          >
            {icon}
          </Box>
          <Typography variant="h5" display="block" fontWeight={600}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            display="block"
          >
            <RenderMarkdown
              source={subtitle}
            />
          </Typography>
          <Stack width="100%">
            {children}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RedeemCard;
