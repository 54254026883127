/* eslint-disable @typescript-eslint/no-explicit-any */
/* ------------------- Globals ------------------- */
import { useEffect, useState } from 'react';

/* ----------------------- 3rd Party ------------------ */
import Router, { useRouter } from 'next/router';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { TextField } from 'formik-mui';
import { I18n, isEmpty } from '@aws-amplify/core';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import Cookies from 'js-cookie';

/* ------------------- Schema ------------------- */
import {
  signUpValidationSchema,
  signInValidationSchema,
  forgotPasswordValidationSchema,
} from '../../schema/auth';

/* -----------------------  Constants ------------------ */
import {
  buttonTitle,
  getInputs,
} from '../../constants/auth';

/* ------------------- Actions ------------------- */
import {
  forgotPasswordVerification,
  signIn,
  signUpPSI,
  resendOTP,
} from '../../functions/auth';

import { getErrorMessage, getInitialValues } from '../../utils/auth';
import RenderMarkdown from '../common/RenderMarkdown';

type Props = {
  variant: string,
  refreshAuth?: () => void,
 };

const AuthInput = (props: Props) => {
  const {
    variant,
    refreshAuth,
  } = props;

  const {
    pathname,
    query: {
      redeemCode,
    },
  } = useRouter();
  const [schema, setSchema] = useState<any>(null);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const clearError = () => setErrorMessage(null);

  const updateAWSConfig = () => {
    // API.configure();
  };

  const handleSignInNavigation = () => {
    Router.push(Router.asPath);
  };

  useEffect(() => {
    if (variant === 'signup') {
      setSchema(signUpValidationSchema);
    } else if (variant === 'login') {
      setSchema(signInValidationSchema);
    } else {
      setSchema(forgotPasswordValidationSchema);
    }
  }, [variant]);

  const getActionName = () => {
    if (variant === 'signup') {
      return buttonTitle.signUp;
    } if (variant === 'login') {
      return buttonTitle.signIn;
    }
    return buttonTitle.forgotPassword;
  };

  const updateAuth = async () => {
    if (typeof refreshAuth === 'function') {
      await refreshAuth();
    }
  };

  const handleForgotPassword = async (values : any) => {
    clearError();
    setIsSubmitting(true);
    try {
      await forgotPasswordVerification(values.username);
      setIsSubmitting(false);

      Router.push(
        `${pathname}/?view=passwordRecover&username=${values.username}`,
        undefined,
        {
          shallow: true,
        },
      );
    } catch (error) {
      const typedError = error as string;
      const exception = decodeURIComponent(typedError).split(':')[0];
      const err = getErrorMessage(exception);
      setErrorMessage(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoggedInNavigation = async () => {
    updateAWSConfig();
    await updateAuth();
    setIsSubmitting(false);
    handleSignInNavigation();
  };

  const navigateToOtpView = (email : string) => {
    const finalPath = `${pathname}/?view=otp${email ? `&email=${email}` : ''}${redeemCode ? `&redeemCode=${redeemCode}` : ''}`;
    Router.push(
      finalPath,
      undefined,
      {
        shallow: true,
      },
    );
  };

  const handleSignIn = (async (values : any) => {
    setIsSubmitting(true);
    clearError();
    try {
      await signIn(values.username, values.password);
      Cookies.set('username', values.username);
      Cookies.set('password', values.password);
      await handleLoggedInNavigation();
    } catch (err : any) {
      const typedError = err as string;
      const exception = decodeURIComponent(typedError).split(':')[0];
      if (exception === 'UserNotConfirmedException') {
        resendOTP(values.username);
        // unverfied account, redirect to otp flow
        navigateToOtpView(values.email);
      } else {
        const errMsg = getErrorMessage(exception);
        setErrorMessage(errMsg);
      }
    } finally {
      setIsSubmitting(false);
    }
  });

  const handleSignup = (async (values : any) => {
    setIsSubmitting(true);
    clearError();
    try {
      const result = await signUpPSI(values.username, values.password, values.email);
      const username = result.user.getUsername();
      Cookies.set('username', username);
      Cookies.set('password', values.password);
      navigateToOtpView(values.email);
    } catch (err : any) {
      const typedError = err as string;
      const exception = decodeURIComponent(typedError).split(':')[0];
      if (exception === 'UsernameExistsException') {
        resendOTP(values.username);
        // unverfied account, redirect to otp flow
        navigateToOtpView(values.email);
      } else {
        const errMsg = getErrorMessage(exception);
        setErrorMessage(errMsg);
      }
    } finally {
      setIsSubmitting(false);
    }
  });

  const getOnSubmitAction = (values : any) => {
    setShowError(true);
    if (variant === 'signup') {
      return handleSignup(values);
    } if (variant === 'login') {
      return handleSignIn(values);
    }
    return handleForgotPassword(values);
  };

  const renderSubmitButton = (formikProps : any) => {
    const {
      values,
    } = formikProps;

    const disableButton = Object.values(values).some((item) => isEmpty(item as string));
    return (
      <Box py={2}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting || disableButton}
        >
          {
                isSubmitting ? (
                  <CircularProgress
                    color="secondary"
                    size={24}
                  />
                ) : I18n.get(getActionName())
              }
        </Button>
      </Box>
    );
  };

  const inputs = getInputs(variant);
  return (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        errorMessage ? (
          <Typography variant="body2" color="error">
            <RenderMarkdown
              source={errorMessage}
            />
          </Typography>
        ) : null
      }
      <>
        <Formik
          initialValues={getInitialValues(variant)}
          validationSchema={schema}
          validateOnBlur={showError}
          validateOnChange={showError}
          onSubmit={getOnSubmitAction}
        >
          {(formikProps) => (
            <Form>
              {inputs.map(({
                type,
                name,
                placeholder: placeholderValue,
                autoFocus,
              }) => (
                <Field
                  type={type}
                  key={type}
                  name={name}
                  placeholder={placeholderValue}
                  component={TextField}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  autoFocus={autoFocus}
                  disabled={isSubmitting}
                />
              ))}
              {renderSubmitButton(formikProps)}
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default AuthInput;
