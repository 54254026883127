/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Child components--------------- */
import AuthInput from './Fields';
import AuthFooter from './Footer';

/* -----------------Props--------------- */
type Props = {
  handleContact?: () => void,
  handleSignIn: () => void,
};

/* -----------------Component--------------- */
const Forgot = (props: Props) => {
  const {
    handleContact,
    handleSignIn,
  } = props;

  return (
    <>
      <AuthInput
        variant="forgot"
      />
      <AuthFooter
        variant="forgot"
        showContactSupport={false}
        primaryAction={() => handleContact && handleContact()}
        secondaryAction={handleSignIn}
      />
    </>
  );
};

export default Forgot;
