/* eslint-disable @typescript-eslint/no-explicit-any */
/* ------------------- Globals ------------------- */
import { useState } from 'react';

/* ----------------------- 3rd Party ------------------ */
import Router, { useRouter } from 'next/router';
import {
  Formik,
  Form,
  Field,
} from 'formik';

import { TextField } from 'formik-mui';
import {
  Box,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';
import { useAtom } from 'jotai';

/* ------------------- Schema ------------------- */
import {
  emailSchema,
} from '../../../schema/auth';

/* -----------------------  Constants ------------------ */
import {
  emailHelperText,
  passwordLessInputProp,
} from '../../../constants/auth';
import { getErrorMessage } from '../../../utils/auth';

/* ------------------- Actions ------------------- */
import {
  signInUsingEmail, signUpUsingEmail,
} from '../../../functions/auth';

/* ------------------- Context ------------------- */
import { useAuthContext } from '../../../context/AuthContext';
import { useTenantContext } from '../../../context/TenantContext';

/* ------------------- Child components ------------------- */
import CustomHelperText from '../../common/CustomHelperText';
import CustomSnackbar from '../../common/Snackbar';

const PasswordLessFields = () => {
  const {
    pathname,
    query: {
      redeemCode,
    },
  } = useRouter();

  const {
    cognitoUserObjectAtom,
  } = useAuthContext();

  const {
    appId,
    tenantIdAuth,
  } = useTenantContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCognitoUserObject] = useAtom(cognitoUserObjectAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [showInvalidDomainSnackBar, setShowInvalidDomainSnackBar] = useState(false);

  const navigateToOtpView = (email : string) => {
    const finalPath = `${pathname}/?view=otp&email=${email}${redeemCode ? `&redeemCode=${redeemCode}` : ''}`;
    Router.push(
      finalPath,
      undefined,
      {
        shallow: true,
      },
    );
  };
  const handleSignup = (async (values : any) => {
    setIsSubmitting(true);
    try {
      const {
        email,
      } = values;
      if (appId) {
        try {
          await signUpUsingEmail(email, appId, tenantIdAuth);
          const result = await signInUsingEmail(email, appId, tenantIdAuth);
          setCognitoUserObject(result);
          navigateToOtpView(email);
        } catch (e) {
          const {
            code,
          } = e as any;
          if (code === 'UserLambdaValidationException') {
            setShowInvalidDomainSnackBar(true);
          }
          // TODO:Catch exception
          if (code === 'UsernameExistsException') {
            const result = await signInUsingEmail(email, appId, tenantIdAuth);
            setCognitoUserObject(result);
            navigateToOtpView(email);
          }
        }
      }
    } catch (err : any) {
      const typedError = err as string;
      const exception = decodeURIComponent(typedError).split(':')[0];
      const errMsg = getErrorMessage(exception);
      setErrorMessage(errMsg);
    } finally {
      setIsSubmitting(false);
    }
  });

  const renderSubmitButton = (formikProps : any) => {
    const {
      values: {
        email,
      },
    } = formikProps;
    const fabLabel = 'Continue';
    return (
      <Box py={2}>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          loading={isSubmitting}
          disabled={isSubmitting || !email}
        >
          {fabLabel}
        </LoadingButton>
      </Box>
    );
  };

  const {
    type,
    placeholder,
    name,
  } = passwordLessInputProp;

  const initialValues = {
    email: '',
  };

  const showError = errorMessage != null;
  const HelperTextComp = () => (
    <CustomHelperText
      error={showError}
      text={showError ? errorMessage : emailHelperText}
    />
  );
  const renderFormikForm = (formikProps : any) => (
    <Form>
      <Field
        type={type}
        key={type}
        name={name}
        placeholder={placeholder}
        component={TextField}
        variant="outlined"
        margin="dense"
        fullWidth
        autoFocus
        helperText={(<HelperTextComp />)}
        disabled={isSubmitting}
      />
      {renderSubmitButton(formikProps)}
    </Form>
  );
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={emailSchema}
        validateOnBlur={showError}
        validateOnChange={showError}
        onSubmit={handleSignup}
      >
        {renderFormikForm}

      </Formik>
      {showInvalidDomainSnackBar ? (
        <CustomSnackbar
          open={showInvalidDomainSnackBar}
          severity="error"
          handleClose={() => setShowInvalidDomainSnackBar(false)}
          message="You must use a permanent email address"
        />
      ) : null}
    </>
  );
};

export default PasswordLessFields;
