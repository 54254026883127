/* -----------------Globals--------------- */
import { commitMutation, graphql } from 'react-relay';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import type {
  AccessCodeData,
  AccessCodeResult,
} from '../../../types/redeem';

/* -------------------Query----------------------- */
const mutation = graphql`
  mutation applyAccessCodeMutation($code: String!) {
    applyAccessCode(code: $code){
      success
      message
      data{
      bundleId
      bundleNumericId
      icon
      bundleName
      planLength
      expiryDate
      }
    }
  }
`;

type Result = {
  applyAccessCode : AccessCodeResult
}
const triggerAccessCode = async (
  code: string,
  environment: RelayModernEnvironment,
) => {
  const promise : Promise<AccessCodeData> = new Promise((resolve, reject) => {
    const variables = {
      code: code.toLocaleUpperCase(),
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        const finalResponse = response as Result;
        const {
          applyAccessCode: {
            success,
            message,
            data: applyAccessCodeResponse,
          },

        } = finalResponse;
        if (success) resolve(applyAccessCodeResponse);
        else {
          reject(message);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
  return promise;
};

export default triggerAccessCode;
