import {
  PasswordRecoveryActionType,
} from './types';

/* -----------------Constants--------------- */
import {
  placeholder,
} from '../../../constants/auth';

const initialState = {
  recoveryCode: undefined,
  snackbarState: undefined,
  errorMessage: undefined,
  isSubmitting: false,
  newPassword: undefined,
  resendingOtp: false,
  reEnteredNewPassword: undefined,
};

const formsProperties = [{
  variant: 'outlined',
  type: PasswordRecoveryActionType.setRecoveryCode,
  placeholder: placeholder.confirmationCode,
  autoFocus: true,
  keyboardType: 'text',
},
{
  variant: 'outlined',
  type: PasswordRecoveryActionType.setNewPassword,
  placeholder: placeholder.newPassword,
  autoFocus: false,
  keyboardType: 'password',
}, {
  variant: 'outlined',
  type: PasswordRecoveryActionType.setReEnteredNewPassword,
  placeholder: placeholder.reenterPassword,
  autoFocus: false,
  keyboardType: 'password',
}];
export {
  formsProperties,
  initialState,
};
