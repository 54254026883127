/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type applyAccessCodeMutationVariables = {
    code: string;
};
export type applyAccessCodeMutationResponse = {
    readonly applyAccessCode: {
        readonly success: boolean;
        readonly message: string;
        readonly data: {
            readonly bundleId: string | null;
            readonly bundleNumericId: number | null;
            readonly icon: string | null;
            readonly bundleName: string | null;
            readonly planLength: string | null;
            readonly expiryDate: string | null;
        } | null;
    };
};
export type applyAccessCodeMutation = {
    readonly response: applyAccessCodeMutationResponse;
    readonly variables: applyAccessCodeMutationVariables;
};



/*
mutation applyAccessCodeMutation(
  $code: String!
) {
  applyAccessCode(code: $code) {
    success
    message
    data {
      bundleId
      bundleNumericId
      icon
      bundleName
      planLength
      expiryDate
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "concreteType": "AccessCodeResult",
    "kind": "LinkedField",
    "name": "applyAccessCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplyCodeSuccessData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bundleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bundleNumericId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bundleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiryDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "applyAccessCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applyAccessCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "64b000b3b4c451b8a0a562a81bf138b4",
    "id": null,
    "metadata": {},
    "name": "applyAccessCodeMutation",
    "operationKind": "mutation",
    "text": "mutation applyAccessCodeMutation(\n  $code: String!\n) {\n  applyAccessCode(code: $code) {\n    success\n    message\n    data {\n      bundleId\n      bundleNumericId\n      icon\n      bundleName\n      planLength\n      expiryDate\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8f59d15c6b4bf55f5ff57f12abeb56c7';
export default node;
