import {
  PasswordRecoveryActionType,
  PasswordRecoveryState,
  PasswordRecoveryAction,
  SnackbarState,
} from './types';

const reducer = (state : PasswordRecoveryState,
  action : PasswordRecoveryAction) : PasswordRecoveryState => {
  switch (action.type) {
    case PasswordRecoveryActionType.setNewPassword:
      return {
        ...state,
        newPassword: action.payload as string,
      };
    case PasswordRecoveryActionType.setReEnteredNewPassword:
      return {
        ...state,
        reEnteredNewPassword: action.payload as string,
      };
    case PasswordRecoveryActionType.setRecoveryCode:
      return {
        ...state,
        recoveryCode: action.payload as string,
      };
    case PasswordRecoveryActionType.setErrorMessage:
      return {
        ...state,
        errorMessage: action.payload as string,
      };
    case PasswordRecoveryActionType.setIsSubmitting:
      return {
        ...state,
        isSubmitting: action.payload as boolean,
      };
    case PasswordRecoveryActionType.setResendingOtp:
      return {
        ...state,
        resendingOtp: action.payload as boolean,
      };
    case PasswordRecoveryActionType.setSnackbarState:
      return {
        ...state,
        snackbarState: action.payload as SnackbarState,
      };
    default:
      return state;
  }
};

export default reducer;
