// @flow
/* -----------------Globals--------------- */
import { useEffect } from 'react';

/* -----------------Routing--------------- */
import { useRouter } from 'next/router';
import { NextPageContext } from 'next';

/* -----------------Constants--------------- */
import { steps, ViewType } from '../src/utils/constants';

/* -----------------Child Components--------------- */
import Layout from '../src/components/redeem/Layout';

interface Props {
  view: ViewType,
}

const Page = (props: Props) => {
  const { view: initialView } = props;
  // Get current view from the query string
  const router = useRouter();
  const { pathname, query: { view } } = router;
  const activeView = view || initialView;
  const step = steps.findIndex((viewArray) => viewArray.includes(`${activeView}`));

  // Redirect to signup if invalid view
  useEffect(() => {
    if (step === -1) {
      router.push(`${pathname}?view=${steps[0][0]}`, undefined, { shallow: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!activeView) {
    return null;
  }
  // This controls the downstream components by listening to the router
  return (
    <Layout step={step} view={activeView as ViewType} />
  );
};

// This makes sure that the query string is read on first render/load
export const getServerSideProps = async (context: NextPageContext) => {
  const { query: { view } } = context;
  return {
    props: {
      view: view || null,
    },
  };
};

export default Page;
