/* -----------------Globals--------------- */
import {
  Dispatch,
} from 'react';

/* -----------------UI--------------- */
import {
  TextField,
  Stack,
} from '@mui/material';
import {
  LoadingButton,
} from '@mui/lab';

/* -----------------Functions--------------- */
import {
  PasswordRecoveryState,
  PasswordRecoveryAction,
  PasswordRecoveryActionType,
} from './containers/types';

/* -----------------Constants--------------- */
import {
  buttonTitle,
  error,
} from '../../constants/auth';
import {
  formsProperties,
} from './containers/constants';

/* -----------------Child components--------------- */
import CustomHelperText from '../common/CustomHelperText';

type Props = {
  handleResendOtp : () => void
  handleChangePassword : () => void
  disableButton? : boolean,
  showError? : boolean,
  state : PasswordRecoveryState,
  showPasswordDontMatchError? : boolean,
  handleDispatch : Dispatch<PasswordRecoveryAction>,
  recoveryHelperText : string
}
/* -----------------Component--------------- */
const PasswordRecovery = (props : Props) => {
  const {
    handleResendOtp,
    handleChangePassword,
    disableButton,
    showError,
    handleDispatch,
    showPasswordDontMatchError,
    recoveryHelperText,
    state: {
      resendingOtp,
      isSubmitting,
      reEnteredNewPassword,
      newPassword,
      errorMessage,
      recoveryCode,
    },
  } = props;

  const PasswordHelperTextComp = () => (showPasswordDontMatchError ? (
    <CustomHelperText
      text={error.password.dontmatch}
      error
    />
  ) : null);

  const RecoveryCodeHelperText = () => (
    <CustomHelperText
      text={showError && errorMessage ? errorMessage
        : recoveryHelperText}
      error={showError}
    />
  );
  const helperTextCompMap :
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<any, React.ReactElement | null > = {
    [PasswordRecoveryActionType.setRecoveryCode]: <RecoveryCodeHelperText />,
    [PasswordRecoveryActionType.setNewPassword]: null,
    [PasswordRecoveryActionType.setReEnteredNewPassword]: <PasswordHelperTextComp />,
  };
  const valueMap :
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<any, string | undefined > = {
    [PasswordRecoveryActionType.setRecoveryCode]: recoveryCode,
    [PasswordRecoveryActionType.setNewPassword]: newPassword,
    [PasswordRecoveryActionType.setReEnteredNewPassword]: reEnteredNewPassword,
  };
  return (
    <Stack
      direction="column"
      gap={2}
    >
      {formsProperties.map(({
        type,
        keyboardType,
        placeholder,
        autoFocus,
        variant,
      }) => (
        <TextField
          variant={variant as 'outlined' | 'standard' | 'filled' | undefined}
          label={placeholder}
          placeholder={placeholder}
          value={valueMap[type]}
          autoFocus={autoFocus}
          helperText={helperTextCompMap[type]}
          onChange={(e) => handleDispatch({
            type,
            payload: e.target.value,
          })}
          type={keyboardType}
        />
      ))}

      {showError ? (
        <LoadingButton
          variant="text"
          color="secondary"
          disabled={resendingOtp}
          loading={resendingOtp}
          onClick={handleResendOtp}
        >
          Resend Code
        </LoadingButton>
      ) : null}
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleChangePassword}
        loading={isSubmitting}
        disabled={disableButton}
      >
        {buttonTitle.resetPassword}
      </LoadingButton>
    </Stack>
  );
};
export default PasswordRecovery;
