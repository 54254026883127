// @flow
/* -----------------Globals--------------- */
import React from 'react';

import { withStyles } from '@mui/styles';
import {
  Typography,
} from '@mui/material';
/* -----------------Markdown--------------- */
import ReactMarkdown from 'react-markdown';
import Link from './Link';

/* -----------------Styles--------------- */
const styles = () => ({
  markdown: {
    '& p:first-of-type': {
      marginTop: 0,
    },
    '& p:last-of-type': {
      marginBottom: 0,
    },
    '&$crossedOut': {
      '& *': {
        textDecoration: 'line-through',
      },
    },
  },
});
/* -----------------Type declarations--------------- */
interface Classes {
  markdown: string,
}

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  source: string,
  customBoldColor? : string
}

/* -----------------Component--------------- */
const RenderMarkdown = (props: Props) => {
  const {
    classes,
    source,
    customBoldColor,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderLink = (renderProps: { href: any; children: any; }) => {
    const { href, children } = renderProps;
    const target = href.startsWith('/') ? '_self' : '_blank';
    return (
      <Link
        href={href}
        target={target}
        sx={{
          textDecoration: 'none',
          color: 'secondary.main',
        }}
      >
        {children}
      </Link>
    );
  };
  const customRenderer = {
    ...(customBoldColor ? {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      strong: ({ children } : any) => (
        <Typography
          color={customBoldColor}
          display="inline"
          variant="body2"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {children}

        </Typography>
      ),
    } : {}),
    link: renderLink,

  };
  return (
    <div className={classes.markdown}>
      <ReactMarkdown
        source={source}
        escapeHtml={false}
        unwrapDisallowed
        disallowedTypes={[]}
        renderers={customRenderer}
      />
    </div>
  );
};

export default withStyles(styles)(RenderMarkdown);
