/* -----------------Globals--------------- */
import { useEffect, useCallback } from 'react';

/* -----------------Routing--------------- */
import { useRouter } from 'next/router';

/* -----------------UI--------------- */
import {
  Box, Container,
} from '@mui/material';

/* -----------------Types--------------- */
import type {
  AccessCodeData,
} from '../../types/redeem';

/* -----------------Context--------------- */
import {
  useTenantContext,
} from '../../context/TenantContext';

/* -----------------Utils--------------- */
import {
  isAuthenticated,
} from '../../utils/auth';

/* -----------------Child components--------------- */
import Auth from './Auth';
import RedeemCode from './RedeemCode';
import RedeemCodeSuccess from './RedeemCodeSuccess';
import Steps from './Steps';
import {
  getInstructions, steps, views,
  ViewType,
} from '../../utils/constants';
import { trackPageView } from '../../analytics/tracking';
import { getTenantIcon } from '../../utils/image';
import { Tenant } from '../../types/tenant';

/* -----------------Component--------------- */

interface Props {
  step: number;
  view: ViewType;
}

interface Params {
  title : string,
  subtitle : string,
  view : ViewType
}
const ContainerRedeemCode = (props: Props) => {
  const { step, view } = props;

  const router = useRouter();
  const {
    isReady,
    query: {
      email,
    },
  } = router;
  const {
    tenant,
    displayName,
    passwordLessFlow,
  } = useTenantContext();
  const changeView = useCallback((newView: ViewType) => {
    const { pathname, query } = router;
    const redeemCodeQuery = query ? query.redeemCode : null;
    const finalPath = `${pathname}/?view=${newView}${redeemCodeQuery ? `&redeemCode=${redeemCodeQuery}` : ''}`;
    router.push(finalPath, undefined, { shallow: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated()) {
      const isAuthView = steps[0].includes(view);
      if (!isAuthView) {
        changeView(steps[0][0] as ViewType);
      }
    } else if (view !== 'redeem' && view !== 'success') {
      changeView(steps[1][0] as ViewType);
    }
  }, [changeView, view]);

  const handleFabClick = (result: AccessCodeData) => {
    const {
      bundleId,
      bundleName,
      planLength,
      expiryDate,
      icon,
    } = result;
    const queryParams = `?view=success&id=${bundleId}&name=${bundleName}&length=${planLength}&date=${expiryDate}&icon=${icon}`;
    router.push(`/${router.pathname}${queryParams}`, undefined, {
      shallow: true,
    });
  };

  const instructions = getInstructions(displayName, email as string, passwordLessFlow);
  const { title, subtitle } = instructions[view];

  useEffect(() => {
    if (isReady) {
      trackPageView({
        pageType: `redeem_${view}`,
      }, tenant as Tenant);
    }
  }, [view, tenant, isReady]);

  const tenantIcon = getTenantIcon(tenant);

  const renderView = (params: Params) => {
    switch (view) {
      case views.signup:
      case views.login:
      case views.forgot:
      case views.reset:
      case views.otp:
        return <Auth {...params} image={tenantIcon} />;
      case views.redeem:
        return (
          <RedeemCode
            {...params}
            handleFabClick={handleFabClick}
          />
        );
      case views.success:
        return <RedeemCodeSuccess />;
      default:
        return <Auth {...params} image={tenantIcon} />;
    }
  };

  return (
    <>
      <Steps activeStep={step} />
      <Container maxWidth="xs">
        <Box py={3}>
          {renderView({ title, subtitle, view })}
        </Box>
      </Container>
    </>
  );
};

export default ContainerRedeemCode;
