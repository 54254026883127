/* eslint-disable react/prop-types */
/* -----------------Global--------------- */
import React from 'react';

/* -----------------Next js--------------- */
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

/* -----------------UI--------------- */
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'onClick' | 'onMouseEnter'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
  href?: NextLinkProps['href'];
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  (props, ref) => {
    const {
      to, linkAs, replace, scroll, shallow, prefetch, locale, href, ...other
    } = props;

    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref
        locale={locale}
      >
        <Anchor
          ref={ref}
          href={href as string}
          {...other}
        />
      </NextLink>
    );
  },
);

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    as: linkAs,
    href,
    ...other
  } = props;

  return (
    <MuiLink
      component={NextLinkComposed}
      linkAs={linkAs}
      underline="none"
      color="secondary"
      ref={ref}
      to={href}
      {...other}
    />
  );
});

export default Link;
