/* -----------------UI--------------- */
import {
  Typography,
} from '@mui/material';

/* -----------------Child components--------------- */
import RenderMarkdown from './RenderMarkdown';

type Props = {
    text : string,
    error? :boolean
}
const CustomHelperText = (props : Props) => {
  const {
    text,
    error,
  } = props;
  return error ? (
    <Typography
      color="error"
      variant="caption"
    >
      {text}
    </Typography>
  ) : (
    <Typography
      textAlign="center"
      variant="caption"
    >
      <RenderMarkdown
        source={text}
      />
    </Typography>
  );
};

export default CustomHelperText;
