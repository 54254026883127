/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type activeCodesQueryVariables = {
    tenantId: string;
    input: string;
};
export type activeCodesQueryResponse = {
    readonly getActiveCodes: ReadonlyArray<{
        readonly bundle: {
            readonly id: string;
            readonly name: string;
            readonly icon: {
                readonly url: unknown;
            } | null;
        };
        readonly codes: ReadonlyArray<string> | null;
    }> | null;
};
export type activeCodesQuery = {
    readonly response: activeCodesQueryResponse;
    readonly variables: activeCodesQueryVariables;
};



/*
query activeCodesQuery(
  $tenantId: String!
  $input: String!
) {
  getActiveCodes(tenantId: $tenantId, input: $input) {
    bundle {
      id
      name
      icon {
        url
        id
      }
    }
    codes
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "activeCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CodeDetails",
        "kind": "LinkedField",
        "name": "getActiveCodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "activeCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CodeDetails",
        "kind": "LinkedField",
        "name": "getActiveCodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fbfd1e6c0c4c6993e927a1a9efd74dc",
    "id": null,
    "metadata": {},
    "name": "activeCodesQuery",
    "operationKind": "query",
    "text": "query activeCodesQuery(\n  $tenantId: String!\n  $input: String!\n) {\n  getActiveCodes(tenantId: $tenantId, input: $input) {\n    bundle {\n      id\n      name\n      icon {\n        url\n        id\n      }\n    }\n    codes\n  }\n}\n"
  }
};
})();
(node as any).hash = '4a03ea56faa53f69f74563806938fb81';
export default node;
