/* -----------------Globals--------------- */
import { graphql } from 'react-relay';
import promiseWrapper from '../../promiseWrapper';
/* -------------------Query----------------------- */
const query = graphql`
query activeCodesQuery($tenantId: String!, $input: String!) {
    getActiveCodes(tenantId: $tenantId, input: $input) {
    bundle{
        id
        name
        icon{
            url
        }
    }
    codes
    }
}
`;

type ActiveCodesResult = {
  bundle: {
    id: string,
    name: string,
    icon: {
      url: string
    }
  },
  codes: string[]
}
type Result = {
  getActiveCodes: [ActiveCodesResult]
}
const fetchActiveCodes = async (
  tenantId: string,
  input: string,
): Promise<[ActiveCodesResult]> => {
  const variables = {
    tenantId,
    input,
  };
  const {
    getActiveCodes,
  } = await promiseWrapper(query, variables) as Result;

  return getActiveCodes;
};

export type { ActiveCodesResult };
export default fetchActiveCodes;
