type SnackbarState = {
    message : string,
    href? : string
  }

enum PasswordRecoveryActionType {
  setRecoveryCode ='setRecoveryCode',
  setSnackbarState= 'setSnackbarState',
  setErrorMessage ='setErrorMessage',
  setIsSubmitting= 'setIsSubmitting',
  setNewPassword= 'setNewPassword',
  setResendingOtp= 'setResendingOtp',
  setReEnteredNewPassword = 'setReEnteredNewPassword',
}
type PasswordRecoveryState = {
    recoveryCode?: string,
    snackbarState: SnackbarState | undefined,
    errorMessage?: string,
    isSubmitting: boolean,
    newPassword?: string,
    resendingOtp: boolean,
    reEnteredNewPassword?: string,
}
type PasswordRecoveryAction = {
    type : PasswordRecoveryActionType,
    payload : unknown
}
export {
  PasswordRecoveryActionType,
};
export type {
  SnackbarState,
  PasswordRecoveryAction,
  PasswordRecoveryState,
};
