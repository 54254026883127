/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Routing--------------- */
import { useRouter } from 'next/router';

/* -----------------Context--------------- */
import { useAuthContext } from '../../context/AuthContext';
import {
  ViewType,
} from '../../utils/constants';
/* -----------------Child components--------------- */
import SignIn from './SignIn';
import SignUp from './SignUp';
import Forgot from './Forgot';
import OneTimePassword from './OneTimePassword';
import PasswordRecoveryContainer from './containers/PasswordRecoveryContainer';

/* -----------------Type declarations--------------- */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any
  }
}

/* -----------------Props--------------- */
type Props = {
  view: ViewType;
};

/* -----------------Component--------------- */
const AuthViews = (props: Props) => {
  const {
    view,
  } = props;

  const router = useRouter();
  const changeView = (newView: string) => {
    const {
      pathname, query: {
        redeemCode,
      },
    } = router;
    router.push(`${pathname}?view=${newView}${redeemCode ? `&redeemCode=${redeemCode}` : ''}`, undefined, { shallow: true });
  };

  const {
    refreshAuth,
  } = useAuthContext();

  const handleContactSupport = () => {
    window.zE('webWidget', 'open');
  };

  const loginView = (
    <SignIn
      handleSignUp={() => changeView('signup')}
      handleForgot={() => changeView('forgot')}
      refreshAuth={refreshAuth}
    />
  );

  const signUpView = (
    <SignUp
      handleChangeView={(v : string) => {
        changeView(v);
      }}
      refreshAuth={refreshAuth}
    />
  );

  const forgotView = (
    <Forgot
      handleContact={handleContactSupport}
      handleSignIn={() => changeView('login')}
    />
  );

  const oneTimePasswordView = (
    <OneTimePassword />
  );

  const passwordRecoveryView = (
    <PasswordRecoveryContainer />
  );
  type View = {
    [key: string]: React.ReactNode
  }
  const views: View = {
    login: loginView,
    signup: signUpView,
    forgot: forgotView,
    otp: oneTimePasswordView,
    passwordRecover: passwordRecoveryView,
  };

  return (
    <>
      {views[view]}
    </>
  );
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default AuthViews;
