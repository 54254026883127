// @flow
import { GraphQLTaggedNode, Variables, fetchQuery } from 'react-relay';
import environment from './env';

const promiseWrapper = async (query: GraphQLTaggedNode, variables: Variables = {}) => {
  const promise = new Promise((resolve, reject) => {
    fetchQuery(environment, query, variables).subscribe({
      next: (data) => resolve(data),
      error: (err : unknown) => reject(err),
    });
  });
  return promise;
};

export default promiseWrapper;
