// @flow

import { isAndroid, isIOS } from 'react-device-detect';
import {
  useRouter,
} from 'next/router';
/* -----------------UI--------------- */
import {
  Box,
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import Image from 'next/image';

/* -----------------Context--------------- */
import {
  useTenantContext,
} from '../../context/TenantContext';
import { useAuthContext } from '../../context/AuthContext';

/* -----------------Utils--------------- */
import {
  getRedeemCodeSuccessText,
} from '../../utils/redeem';

/* -----------------Types--------------- */
import type {
  AccessCodeData,
} from '../../types/redeem';

import HeaderAccessGranted from '../common/HeaderAccessGrant';

const RedeemCodeSuccess = () => {
  const {
    isReady,
    query: {
      id: bundleId,
      name: bundleName,
      length: planLength,
      date: expiryDate,
      icon,
    },
  } = useRouter();
  const accessCodeResult = {
    bundleId,
    bundleName,
    planLength,
    expiryDate,
    icon,
  } as unknown;
  const {
    appLinkAndroid,
    appLinkIOS,
    barcode,
    appName,
  } = useTenantContext();

  const {
    user,
  } = useAuthContext();
  const userEmail = user ? user.attributes.email : null;

  const QRCode = () => (
    <Image
      width={150}
      objectFit="contain"
      height={150}
      loading="eager"
      src={barcode}
      alt="applink"
    />
  );

  const AndroidBadge = () => (
    <Link href={appLinkAndroid} target="_blank">
      <Image
        width={(48 * 564) / 168}
        objectFit="contain"
        height={48}
        loading="eager"
        src="/badges/google_play.png"
        alt="Google Play"
      />
    </Link>
  );

  const AppleBadge = () => (
    <Link href={appLinkIOS} target="_blank">
      <Image
        width={(48 * 119.66407) / 40}
        objectFit="contain"
        height={48}
        loading="eager"
        src="/badges/app_store.svg"
        alt="App Store"
      />
    </Link>
  );

  const DownloadOptions = () => {
    if (isIOS) {
      return (
        <AppleBadge />
      );
    }
    if (isAndroid) {
      return (
        <AndroidBadge />
      );
    }
    return (
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} container justifyContent="center">
          <QRCode />
        </Grid>
        <Grid item xs={6} container justifyContent="center">
          <AppleBadge />
        </Grid>
        <Grid item xs={6} container justifyContent="center">
          <AndroidBadge />
        </Grid>
      </Grid>
    );
  };

  const renderViews = () => {
    const typedAccessCodeResult = accessCodeResult as AccessCodeData;
    const markdown = getRedeemCodeSuccessText(
      typedAccessCodeResult,
      userEmail,
      appName,
    );

    return (
      <Card>
        <Box p={2}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item>
              <HeaderAccessGranted
                icon={typedAccessCodeResult.icon}
                markdown={markdown}
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">Download the app</Typography>
            </Grid>
            <Grid item>
              <DownloadOptions />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    isReady ? renderViews() : null
  );
};

export default RedeemCodeSuccess;
