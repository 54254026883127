// @flow
/* -----------------Globals--------------- */
import { useTheme } from '@mui/system';

/* -----------------Next js--------------- */
import Image from 'next/image';

/* -----------------UI--------------- */
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

/* -----------------Child components--------------- */
import RenderMarkdown from './RenderMarkdown';

type Props = {
  markdown: string,
  icon: string
}
const HeaderAccessGranted = (props: Props) => {
  const {
    markdown,
    icon,
  } = props;

  const theme = useTheme();

  return (
    <Box width="100%" textAlign="center">
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid
          item
        >
          <Image
            width={120}
            height={120}
            loading="eager"
            src={icon}
            alt="headerlogo"
            style={{
              borderRadius: '22%',
              border: `1px solid ${theme.palette.divider}`,
            }}
          />
        </Grid>
        <Grid item>

          <Typography
            variant="h6"
            align="center"
          >
            Access Granted!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            <RenderMarkdown source={markdown} />
          </Typography>
        </Grid>
        <Grid item container>
          <Box width="100%">
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderAccessGranted;
