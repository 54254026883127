import { Typography, Box } from '@mui/material';
import RenderMarkdown from '../../common/RenderMarkdown';

const Footer = () => {
  const markdownText = 'Need help? **Contact Support**';

  const openZendesk = () => {
    window.zE('webWidget', 'open');
  };
  return (
    <Box
      onClick={openZendesk}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Typography variant="body2">
        <RenderMarkdown
          source={markdownText}
          customBoldColor="seconadry"
        />
      </Typography>
    </Box>
  );
};

export default Footer;
