/* eslint-disable @typescript-eslint/ban-ts-comment */
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useTheme,
} from '@mui/material';

/* -----------------Icons--------------- */
import {
  CellphoneArrowDown,
  LoginVariant,
  ConsoleLine,
} from 'mdi-material-ui';

interface Props {
  activeStep : number
}

type StepCompProps = {
  active : boolean,
  completed : boolean,
  icon : number
}
const Steps = (props : Props) => {
  const {
    activeStep,
  } = props;

  const theme = useTheme();

  const StepIcon = (stepCompProps : StepCompProps) => {
    const {
      active,
      completed,
      icon,
    } = stepCompProps;

    const icons = {
      0: <LoginVariant />,
      1: <ConsoleLine />,
      2: <CellphoneArrowDown />,
    };
    return (
      <Box color={active || completed ? 'secondary.main' : theme.palette.text.disabled}>
        {/* @ts-ignore */}
        {icons[icon - 1]}
      </Box>
    );
  };

  // TODO: Future enhancement - customize horizontal "connector"
  const steps = [
    'Sign Up / Login',
    'Enter Redemption Code',
    'Download App',
  ];

  return (
    <Paper square elevation={0}>
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const color = activeStep >= index
              ? theme.palette.secondary.main
              : theme.palette.text.disabled;
            return (
              <Step
                sx={{
                  '& .MuiStepConnector-line': {
                    borderColor: color,
                  },
                }}
                key={label}
              >
                <StepLabel StepIconComponent={StepIcon}>
                  <Box
                    sx={{ fontWeight: activeStep >= index ? 600 : 'undefined' }}
                    color={color}
                  >
                    {label}
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Container>
    </Paper>
  );
};
export default Steps;
